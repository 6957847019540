<template>
  <div class="course-info">
    <CCard class="course-info-card">     
      <img v-if="!templateData?.length"
   class="img-course"
   src="https://slc-campus.avallainmagnet.com/0fd5218f-0b57-48ad-bc07-c4b0e6b787ba/system/uploads/unity/core/course_node/cover_image/b6d34df7-530e-4dbf-8dad-97d533d1d92c/s3_246169_advanced_grammar_for_healthcare_cover.png?version=1697402702"
 />
      <CCardBody class="p-2 position-relative">
        <h2 class="text-primary">{{ trainingCourse.course_title }}</h2>
      <TemplatePreview v-if="templateData?.length" :templateData="templateData" />
        <div v-else>
          <CRow>
            <CCol md="2" sm="3">
              <CRow>
                <label class="col-md-12 mb-1 text-gray">Course Type</label>
                <p class="col-md-12 mb-1 text-black">
                  {{ trainingCourse.course_test_types.course_test_type }}
                </p>
              </CRow>
            </CCol>
            <CCol md="2" sm="3">
              <CRow>
                <label class="col-md-12 mb-1 text-gray">Healthcare Sector</label>
                <p class="col-md-12 mb-1 text-black">
                  {{ trainingCourse.healthcare_sector | startCase }}
                </p>
              </CRow>
            </CCol>
            <CCol md="2" sm="3">
              <CRow>
                <label class="col-md-12 mb-1 text-gray">Language</label>
                <p class="col-md-12 mb-1 text-black">
                  {{ trainingCourse.language.language }}
                </p>
              </CRow>
            </CCol>
            <CCol md="3" sm="3">
              <CRow>
                <label class="col-md-12 mb-1 text-gray">CEFR Level</label>
                <p class="col-md-12 mb-1 text-black">
                  {{ trainingCourse.cefr_level }}
                </p>
              </CRow>
            </CCol>
            <CCol md="2" sm="3">
              <CRow>
                <label class="col-md-12 mb-1 text-gray">Learning Hrs</label>
                <p class="col-md-12 mb-1 text-black">
                  {{ trainingCourse.learning_hours }}
                </p>
              </CRow>
            </CCol>
            <CCol md="2" sm="3">
              <CRow>
                <label class="col-md-12 mb-1 text-gray">Reference</label>
                <p class="col-md-12 mb-1 text-black">
                  {{ trainingCourse.course_ref }}
                </p>
              </CRow>
            </CCol>
            <CCol md="2" sm="3">
              <CRow>
                <label class="col-md-12 mb-1 text-gray">Duration Access</label>
                <p class="col-md-12 mb-1 text-black">
                  {{ trainingCourse.duration_months }}
                </p>
              </CRow>
            </CCol>
            <CCol md="2" sm="3">
              <CRow>
                <label class="col-md-12 mb-1 text-gray">CPD Points</label>
                <p class="col-md-12 mb-1 text-black">
                  {{ trainingCourse.cpd_points || "--" }}
                </p>
              </CRow>
            </CCol>
            <CCol md="3" sm="3">
              <CRow>
                <label class="col-md-12 mb-1 text-gray">Lessons</label>
                <p class="col-md-12 mb-1 text-black">
                  {{ course.options_count }}
                </p>
              </CRow>
            </CCol>
            <CCol md="2" sm="3">
              <CRow>
                <label class="col-md-12 mb-1 text-gray">No.of Modules</label>
                <p class="col-md-12 mb-1 text-black">
                  {{ course.modules_count }}
                </p>
              </CRow>
            </CCol>
          </CRow>
        </div>
        <div class="d-flex justify-content-end">
          <CButton color="primary" @click="openCourseModalCallBack"
            >Login/Register</CButton
          >
        </div>
      </CCardBody>
    </CCard>
  </div>
</template>
<script>
import * as _ from "lodash";
import TemplatePreview from "@/components/reusable/CourseTemplateView";

export default {
  components:{
    TemplatePreview
  },
  props: ["course", "templateData"],
  computed: {
    trainingCourse() {
      return this.course?.training_course;
    },
  },
  filters: {
    startCase(data) {
      return data ? _.startCase(data) : data;
    },
  },
  methods: {
    openCourseModalCallBack() {
      this.$emit("closeCourseInfo",true);
    },
  },
};
</script>
<style lang="scss" scoped>
.img-course {
  max-height: 200px;
}
.text-gray {
  color: #6f6f6f;
  font-size: 13px;
}
</style>
