<template>
  <div class="partner-list">
    <span class="close" @click="closeModal">&times;</span>
    <CRow>
      <CCol md="4" v-for="(partner, index) of partners" :key="index">
        <CCard class="partner-card">
          <img class="img-partner" :src="partner.img" />
          <CCardBody class="p-2 position-relative">
            <div>
              <h6 class="text-2-truncate slc-title" :title="partner.name">
                {{ partner.name }}
              </h6>
            </div>
            <div class="partner-footer position-absolute bottom-0">
              <CButton
                variant="ghost"
                size="sm"
                color="info"
                @click="viewPartnerInfo(partner)"
                >View Profile</CButton
              >
            </div>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>
<script>
import { CONSTANT } from "@/helpers/helper";
export default {
  data() {
    return {
      partners: CONSTANT.partners,
    };
  },
  methods: {
    closeModal() {
      this.$emit("modalCallBack", false);
    },
    viewPartnerInfo(partner) {
      this.closeModal();
      const { partner_id } = partner;
      this.$router.push(`/tfw/partner/${partner_id}/profile`);
    },
  },
};
</script>
<style lang="scss" scoped>
.text-2-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.slc-title {
  font-weight: 500;
  line-height: 1.235;
  font-size: 1.2rem;
}

.close {
  position: absolute;
  top: 0px;
  right: 28px;
  color: #f1f1f1;
  font-size: 40px;
  font-weight: bold;
  transition: 0.3s;
}

.close:hover,
.close:focus {
  color: #bbb;
  text-decoration: none;
  cursor: pointer;
}

.partner-card {
  min-height: 250px;
}

.partner-footer {
  bottom: 5px;
}

.img-partner {
  height: 150px;
}
</style>
