<template>
  <div class="section-1 position-relative">
    <div class="content">
      <h1 class="text-white heading-1">
        A CONNECTED WORLD FOR HEALTHCARE CAREERS
      </h1>
      <h3 class="text-white subhead mb-3">
        An interconnected hub for everyone involved in the development of a
        sustainable global healthcare workforce
      </h3>
      <div class="mb-3 job-button">
        <CLink
          class="btn btn-register mb-2 mr-3"
          @click="scrollMeTo('tfw-section-4')"
          >Learn More</CLink
        >
        <CLink
          class="btn btn-register mr-3 mb-2"
          to="/tfw/registration/candidate"
          >JOIN HERE</CLink
        >
        <CLink
          class="btn btn-register mb-2"
          @click="scrollMeTo('sec-8-heading')"
          >JOB OPPORTUNITIES</CLink
        >
      </div>
      <!-- <div>
        <h1 class="heading-3" v-if="getTFWCandidateCount">
          {{ candidateCount }} Candidates Registered and Counting...
        </h1>
      </div> -->
    </div>
    <div class="suby">
      <img src="/img/tfw/hero-image.png" alt="" class="landing-image" />
    </div>
    <img src="/img/tfw/arrow.svg" alt="" class="arrow" />
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { getLocalOrgDetail } from "../../../helpers/helper";
export default {
  name: "TFWSection1",
  computed: {
    ...mapGetters(["getTFWCandidateCount", "getOrgDetail"]),
    candidateCount(){
      return this.getTFWCandidateCount + 5000;
    }
  },
  methods: {
    ...mapActions(["fetchTFWCandidateCount"]),
    scrollMeTo(refName) {
      let element = document.getElementById(refName);
      if (element) {
        element.scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "nearest",
        });
      }
    },
    triggerFetchCount() {
      const customer_id = getLocalOrgDetail()?.customer_uid;
      if (customer_id) this.fetchTFWCandidateCount(customer_id);
    },
  },
  mounted() {
    this.triggerFetchCount();
  },
  watch: {
    getOrgDetail(newVal, oldVal) {
      if (newVal) {
        this.triggerFetchCount();
      }
    },
  },
};
</script>