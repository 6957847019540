<template>
  <div class="tfw-course-list">
    <span class="close" @click="closeModal">&times;</span>
    <CRow v-if="!courseInfo.isShowInfo">
      <CCol md="4" v-for="item of courseList" :key="item.course_id">
        <CCard class="course-card">
          <img class="img-course" :src="item.thumbnail" />
          <CCardBody class="p-2 position-relative">
            <div>
              <h6 class="text-2-truncate slc-title" :title="item.name">
                {{ item.name }}
              </h6>
            </div>
            <div class="course-footer position-absolute bottom-0">
              <CButton
                variant="ghost"
                size="sm"
                color="info"
                @click="viewCourseInfo(item)"
                >View Course</CButton
              >
            </div>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
    <div v-else>
      <CourseInfoVue
        :course="courseInfo.data"
        :templateData="courseInfo.templateData"
        @closeCourseInfo="closeCourseInfo"
      />
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import CourseInfoVue from "@/containers/TFW/LandingPage/CourseInfo.vue";
import { CONSTANT } from "@/helpers/helper";
export default {
  components: {
    CourseInfoVue,
  },
  data() {
    return {
      courseInfo: {
        isShowInfo: false,
        data: null,
        templateData: null,
      },
    };
  },
  computed: {
    ...mapGetters([
      "noMoreDataFromCourseList",
      "isFetchingCourseList",
      "courseTemplate",
    ]),
    courseList() {
      const courses = CONSTANT.courses;
      return courses || [];
    },
  },
  methods: {
    ...mapActions(["getCourseList", "fetchCourseTemplate"]),

    viewCourseInfo(data) {
      this.$emit("modalCallBack", false);
      this.$router.push(`/tfw/course/${data.course_id}`);
    },
    closeModal() {
      if (this.courseInfo.isShowInfo) this.courseInfo.isShowInfo = false;
      else {
        this.$emit("modalCallBack", false);
      }
    },
    closeCourseInfo(payload = false) {
      this.courseInfo.isShowInfo = false;
      this.$emit("closeCourseList", payload);
    },
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
.text-2-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.slc-title {
  font-weight: 500;
  line-height: 1.235;
  font-size: 1.2rem;
}

.close {
  position: absolute;
  top: 0px;
  right: 28px;
  color: #f1f1f1;
  font-size: 40px;
  font-weight: bold;
  transition: 0.3s;
}

.close:hover,
.close:focus {
  color: #bbb;
  text-decoration: none;
  cursor: pointer;
}

.course-card {
  min-height: 280px;
}

.course-footer {
  bottom: 5px;
}
</style>
