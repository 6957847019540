<template>
  <div class="tfw-course-list">
    <span class="close" @click="closeModal">&times;</span>
    <CRow v-if="!courseInfo.isShowInfo">
      <CCol
        md="3"
        v-for="item of courseList"
        :key="item.training_course.training_course_id"
      >
        <CCard class="course-card">
          <img
            class="img-course"
            src="https://slc-campus.avallainmagnet.com/0fd5218f-0b57-48ad-bc07-c4b0e6b787ba/system/uploads/unity/core/course_node/cover_image/b6d34df7-530e-4dbf-8dad-97d533d1d92c/s3_246169_advanced_grammar_for_healthcare_cover.png?version=1697402702"
          />
          <CCardBody class="p-2 position-relative">
            <div>
              <h6
                class="text-2-truncate slc-title"
                :title="item.training_course.course_title"
              >
                {{ item.training_course.course_title }}
              </h6>
            </div>
            <div class="course-footer position-absolute bottom-0">
              <CButton
                variant="ghost"
                size="sm"
                color="info"
                @click="viewCourseInfo(item)"
                >View Course</CButton
              >
            </div>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
    <div v-else>
      <CourseInfoVue
        :course="courseInfo.data"
        :templateData="courseInfo.templateData"
        @closeCourseInfo="closeCourseInfo"
      />
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import CourseInfoVue from "@/containers/TFW/LandingPage/CourseInfo.vue";
export default {
  components: {
    CourseInfoVue,
  },
  data() {
    return {
      courseInfo: {
        isShowInfo: false,
        data: null,
        templateData: null,
      },
    };
  },
  computed: {
    ...mapGetters([
      "courseList",
      "noMoreDataFromCourseList",
      "isFetchingCourseList",
      "courseTemplate",
    ]),
  },
  methods: {
    ...mapActions(["getCourseList", "fetchCourseTemplate"]),
    onBodyScroll(e) {
      if (this.noMoreDataFromCourseList) return;
      if (
        e.target.clientHeight + e.target.scrollTop >=
          e.target.scrollHeight - 1 &&
        !this.noMoreDataFromCourseList &&
        !this.isFetchingCourseList &&
        !this.courseInfo.isShowInfo
      ) {
        this.getCourseList({
          pagination: true,
        });
      }
    },
    viewCourseInfo(data) {
      this.fetchCourseTemplate(data.training_course.training_course_id).then(
        (res) => {
          if (res.status == 200) {
            this.courseInfo.templateData = res.data;
            this.courseInfo.data = data;
            this.courseInfo.isShowInfo = true;
          }
        }
      );
    },
    closeModal() {
      if (this.courseInfo.isShowInfo) this.courseInfo.isShowInfo = false;
      else {
        this.$emit("modalCallBack", false);
      }
    },
    closeCourseInfo(payload=false) {
      this.courseInfo.isShowInfo = false;
      this.$emit("closeCourseList",payload);
    },
  },
  mounted() {
    this.getCourseList().then((res) => {
      const ele = document.getElementById("course-model");
      if (ele) {
        ele.addEventListener("scroll", this.onBodyScroll);
      }
    });
  },
  beforeDestroy() {
    const ele = document.getElementById("course-model");
    if (ele) {
      ele.removeEventListener("scroll", this.onBodyScroll);
    }
  },
};
</script>
<style lang="scss" scoped>
.text-2-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.slc-title {
  font-weight: 500;
  line-height: 1.235;
  font-size: 1.2rem;
}

.close {
  position: absolute;
  top: 0px;
  right: 28px;
  color: #f1f1f1;
  font-size: 40px;
  font-weight: bold;
  transition: 0.3s;
}

.close:hover,
.close:focus {
  color: #bbb;
  text-decoration: none;
  cursor: pointer;
}

.course-card {
  min-height: 280px;
}

.course-footer {
  bottom: 5px;
}
</style>
