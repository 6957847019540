<template>
  <div id="tfw-section-5" class="section-5">
    <div class="wrapper m-auto">
      <div class="wrap m-auto">
        <h2 class="heading-4">One Place. One Purpose. One Healthcare World</h2>
        <div class="subhead">Connecting your career development.</div>
      </div>
      <div class="row">
        <div class="col-md-4 plain-div-block">
          <img
            src="/img/tfw/2.png"
            sizes="100vw"
            alt=""
            class="tablet-screen-shot"
          />
          <h4 class="black-text">Make Your Profile Public or Private</h4>
          <p class="paragraph-3">
            Automatic job alerts.<br />
            100% matched against your training<br />and qualifications
          </p>
        </div>
        <div class="col-md-4 plain-div-block">
          <img
            src="/img/tfw/3.png"
            sizes="100vw"
            alt=""
            class="tablet-screen-shot"
          />
          <h4 class="black-text">Upskill Your Career</h4>
          <p class="paragraph-3">
            English Language Training,<br />Skills Upgrade and CME/CPD
            Training<br />and more!
          </p>
        </div>
        <div class="col-md-4 plain-div-block">
          <img
            src="/img/tfw/4-1.png"
            sizes="100vw"
            alt=""
            class="tablet-screen-shot"
          />
          <h4 class="black-text">Professional Documents in One Place</h4>
          <p class="paragraph-3">
            Easy to access and share.<br />Owned & fully controlled by you.<br />Secure
            and safe delivery
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "TFWSection5",
};
</script>