<template>
  <div class="pl-2">
    <CModal color="primary" :show.sync="isShowPopup" :closeOnBackdrop="false">
      <template #header>
        <h5>Switch Customer User Type</h5>
        <CButtonClose @click="modalCallBack()" />
      </template>
      <template #footer>
        <CButton color="secondary" @click="modalCallBack()">Cancel</CButton>
        <CButton color="primary" form="switchUserType" type="submit"
          >Switch
        </CButton>
      </template>
      <div>
        <ValidationObserver ref="switchUserType" v-slot="{ handleSubmit }">
          <form id="switchUserType" @submit.prevent="handleSubmit(onSubmit)">
            <CRow class="row mb-3">
              <label class="required col-lg-5 text-black">
                Select User Type
              </label>
              <div class="col-lg-7">
                <ValidationProvider
                  :rules="{ required: true }"
                  v-slot="{ errors }"
                >
                  <Select
                    name="user_type"
                    :value="user_type"
                    @input="handleChangeSelect"
                    :options="
                      options && options['customerUserRoles']
                        ? options['customerUserRoles']
                        : []
                    "
                    :error="errors[0]"
                    :clearable="false"
                  />
                </ValidationProvider>
              </div>
            </CRow>
          </form>
        </ValidationObserver>
      </div>
    </CModal>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import Select from "@/components/reusable/Fields/Select";
export default {
  components: { Select },
  props: {
    isShowPopup: {
      type: Boolean,
      default: () => false,
    },
    currentUserType: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      user_type: null,
      payload: {},
      recruiter_opt: {
        code: "customer_recruiter",
        label: "Customer Recruiter",
      },
      customer_admin_opt: { code: "customer_admin", label: "Customer Admin" },
    };
  },
  computed: {
    ...mapGetters(["customerUserRoles", "getRole", "getRoleScopes"]),
    options() {
      return {
        customerUserRoles: this.customUserRoles || [],
      };
    },
    customUserRoles() {
      let opt = this.customerUserRoles || [];
      if (
        this.getRoleScopes.includes("customer_recruiter") &&
        !opt.some(({ code }) => code === "customer_recruiter")
      ) {
        opt.unshift(this.recruiter_opt);
      }
      if (
        this.getRoleScopes.includes("customer_admin") &&
        !opt.some(({ code }) => code === "customer_admin")
      ) {
        opt.unshift(this.customer_admin_opt);
      }
      return opt;
    },
  },
  methods: {
    ...mapActions(["showToast"]),
    handleChangeSelect(name, value) {
      this[name] = value;
      this.payload = {
        ...this.payload,
        [name]: value ? value.id || value.code || value : null,
      };
    },
    async onSubmit() {
      const isValid = await this.$refs.switchUserType.validate();
      if (!isValid) {
        this.showToast({
          class: "bg-danger text-white",
          message: "please fill mandatory fields!",
        });
        return;
      }
      this.modalCallBack(this.user_type);
    },
    modalCallBack(data) {
      this.$emit("modalCallBack", data);
    },
  },
  mounted() {
    const userType = this.currentUserType;
    this.user_type = {
      code: userType?.code,
      label: userType?.label,
    };
  },
};
</script>