<template>
  <div :class="isMobile ? (isSmall ? '' : 'p-2') : ''">
    <CHeader
      class="bg-sidebar-color sticky-top"
      id="header"
      :style="isMobile && isPublicJobDetail ? '' : ''"
      fixed
      with-subheader
      light
      v-if="!isDemoPage"
    >
      <CToggler
        v-if="
          currentUserRole &&
          ![Role.systemCandidate, Role.customerCandidate].includes(
            currentUserRole
          )
        "
        in-header
        class="d-lg-none px-0"
        v-c-emit-root-event:toggle-sidebar-mobile
      >
        <span class="menu-toggle fa fa-bars" :class="getTextColor"></span>
      </CToggler>
      <div style="margin-top: auto; margin-bottom: auto">
        <div class="logo">
          <div>
            <a
              :href="getOrgWebsite || 'javascript:void(0)'"
              :target="getOrgWebsite ? '_blank' : '_self'"
            >
              <img
                class="p-1 d-inline-block mw-100"
                :src="getHeaderLogo"
                :style="`${IS_DOH_THEME ? '' : 'max-height: 61px'}`"
                @error="$event.target.src = '/img/new_tf_logo.svg'"
                alt="logo"
              />
            </a>
          </div>
        </div>
      </div>
      <div v-if="currentUserRole && !isIncustomeruserRole">
        <the-dynamic-nav-headers />
      </div>
      <div
        v-if="isAdminLogin"
        class="d-flex flex-row align-items-center"
        :class="mobile ? 'float-left' : 'mr-4 headerIconHolder'"
      >
        <div class="text-secondary mr-5 cursor-pointer">
          <i
            class="fa-solid fa-pen"
            style="font-size: large"
            @click="openReportIssueModal()"
          ></i>
        </div>
        <div class="notification-bell cursor-pointer">
          <span @click="isOpenNotification">
            <i class="fas fa-bell bell-icon"></i>
          </span>
          <span class="badge badge-md badge-circle badge-count text-white">
            {{ notificationMessages }}
          </span>
        </div>
        <div
          class="minify-notification arrow"
          v-if="this.getShortNotify && !isShowNotification && !mobile"
        >
          <div class="container" align="center">
            <p class="mb-0">
              You have received a new -
              {{ status(this.getNotification[0].alert_type_id).label }}
            </p>
          </div>
        </div>
        <Notification
          v-if="isShowNotification && !mobile"
          @notifiModalCallBack="notifiModalCallBack"
        />
      </div>
      <div
        id="headerIconHolder"
        class="d-flex align-items-center responsive"
        :class="`${isAdminLogin ? '' : 'list-unstyled'}`"
        v-if="getImpersonationStatus"
      >
        <CandidateNotification v-if="isCandidate" />
        <div v-if="isInterviewer && currentUserRole" class="mr-3 pr-3 calender">
          <div>
            <span @click="isShowCalendar = true" style="cursor: pointer">
              <i class="far fa-calendar calender-icon text-primary"></i>
            </span>
          </div>
        </div>
        <div
          id="loginUsername"
          ref="loginUsername"
          class="flex-column alignWidth mr-4"
          v-if="!getImpersonationStatus.impersonation"
        >
          <div v-if="getUserName">
            <span :class="getTextPrimaryColor">Hello {{ getUserName }}</span>
            <span class="d-block" :class="getTextColor" v-if="getOrgName">
              <small>{{ getOrgName }}</small>
            </span>
            <span class="d-block" :class="getTextColor" v-else>
              <small>{{ getCustomerNameFromAccessToken }}</small>
            </span>
          </div>
        </div>
        <div
          class="d-flex flex-column linespcad title text alignWidth ml-2"
          v-if="getImpersonationStatus.impersonation"
        >
          <div>
            <span>You are now</span>
          </div>
          <div>
            <span>Facility Admin for</span>
          </div>
          <div>
            <span>{{ getImpersonationStatus.customer_name }}</span>
          </div>
        </div>
        <div
          name="customer-type-label"
          v-if="isCustomerUser"
          class="d-flex flex-column linespcad title text alignWidth"
          :class="{ 'mr-2': desktop }"
        >
          <div>
            <span>You are now</span>
          </div>
          <div>
            <span :id="currentUserType.code" name="customer-type">{{
              customerType
            }}</span>
          </div>
        </div>
        <div
          v-if="currentUserRole"
          class="d-flex flex-row align-items-center p-0"
          :class="{ 'mr-2': desktop }"
        >
          <TheHeaderDropdownAccnt v-if="currentUserRole" />
        </div>
        <div
          v-if="!currentUserRole"
          class="d-flex flex-row align-items-center w-100"
        >
          <span
            class="signup-link align-right text-nowrap"
            :class="getTextPrimaryColor"
            v-if="!isRegisterPage && getOrgAcronym && !isIEFPage && !isCampaign"
          >
            <span v-if="!isMobile">Not Registered?</span>
            <CButton
              name="register-now"
              color="primary"
              class="fs-15 ml-1 mr-2 text-white"
              @click="userRegister"
              >Sign&nbsp;up&nbsp;now</CButton
            >
          </span>

          <span
            class="home-link align-right mr-4"
            :class="getTextPrimaryColor"
            v-if="isJobBoard"
          >
            <CButton
              name="home"
              color="link"
              class="font-weight-bold active px-0 fs-15 mr-2"
              :class="getTextColor"
              @click="redirectToHome"
              >Back To Home</CButton
            >
          </span>
          <span
            class="signup-link align-right text-nowrap"
            :class="getTextPrimaryColor"
            v-if="
              (isRegisterPage && !(getOrgVariant == Variant.campaign)) ||
              isIEFPage
            "
          >
            <CButton
              v-if="!isCampaignCandidate"
              name="register-now"
              color="primary"
              class="fs-15 mr-2 text-white"
              @click="userSigin"
              >Sign In</CButton
            >
          </span>
        </div>
        <div class="d-flex align-item-center">
          <CDropdown
            inNav
            class="c-header-nav-items faq list-style-none"
            placement="bottom-end"
            add-menu-classes="pt-0"
          >
            <template #toggler>
              <CHeaderNavLink>
                <div class="c-avatar text">
                  <i class="fa fa-question-circle" aria-hidden="true"></i>
                </div>
              </CHeaderNavLink>
            </template>
            <CDropdownHeader tag="div" class="text-center" color="light">
              <strong>Help</strong>
            </CDropdownHeader>
            <a class="dropdown-item faq" @click="aboutModel()"> About </a>
            <a
              class="dropdown-item"
              v-if="
                currentUserRole ||
                isRegisterPage ||
                (!isRegisterPage && getOrgAcronym)
              "
              @click="routFaq()"
            >
              FAQ
            </a>
          </CDropdown>
        </div>
      </div>
      <router-link to="/faq" class="mr-1 faq" v-if="!getImpersonationStatus">
        <i class="fa fa-question-circle" aria-hidden="true"></i>
      </router-link>
      <div
        id="loginUsernameMobile"
        v-if="!getImpersonationStatus.impersonation"
        ref="loginUsernameMobile"
      >
        <div v-if="getUserName">
          <span class="d-block" :class="getTextColor" v-if="getOrgName">
            <span :class="getTextPrimaryColor">Hello {{ getUserName }} | </span>
            <small>{{ getOrgName }}</small>
          </span>
        </div>
      </div>
    </CHeader>

    <div class="additional-space"></div>
    <AlertModal
      v-if="
        [Role.customerAdmin, Role.customerRecruiter].includes(
          currentUserRole
        ) && getShowAlert
      "
      :isShowAlertPopup="getShowAlert"
      @alertModalCallBack="alertModalCallBack"
    />
    <Notification
      v-if="isShowNotification && mobile && isAdminLogin"
      @notifiModalCallBack="notifiModalCallBack"
    />
    <Calendar
      v-if="isShowCalendar"
      :isShowPopup="isShowCalendar"
      @calendarModalCallBack="calendarModalCallBack"
    />
    <CModal
      id="aboutModal"
      title="About"
      color="primary"
      centered:false
      :show.sync="isShowAboutModal"
    >
      <p>
        <strong>TalentFind by OHR</strong>
        <br />
        <strong>Last Deployed:</strong> {{ VUE_APP_LAST_DEPLOYED }}
        <br />
        <strong>Release Version:</strong> {{ VUE_APP_RELEASE_VERSION }}
      </p>
    </CModal>
    <Modal
      v-if="allocateModal.isShowPopup"
      :modalTitle="allocateModal.modalTitle"
      :modalColor="allocateModal.modalColor"
      :modalContent="allocateModal.modalContent"
      :isShowPopup="allocateModal.isShowPopup"
      :buttons="allocateModal.buttons"
      :modalCallBack="allocateModalCallBack"
      :closeOnBackdrop="false"
    />
    <ReportIssuePage
      v-if="reportIssueModal.isShowPopup"
      :isShowPopup="reportIssueModal.isShowPopup"
      :modalTitle="reportIssueModal.modalTitle"
      :modalCallBack="reportIssueModalCallBack"
    />
    <WelcomeModel
      :isShowPopup="isShowWelcome"
      title="Welcome SLC"
      message="Please create your courses and enter the details here!"
      :closeModal="welcomeModeCallBack"
    />
  </div>
</template>

<script>
import TheDynamicNavHeaders from "./TheDynamicNavHeaders";
import TheHeaderDropdownAccnt from "./TheHeaderDropdownAccnt";
import Notification from "@/containers/Communication/Notification/NotificationDropDown";
import CandidateNotification from "@/containers/Communication/Notification/CandidateNotification";
import AlertModal from "@/containers/EaseAccess/AlertModal";
import Modal from "@/components/reusable/Modal";
import Calendar from "@/containers/EaseAccess/CalendarModal";
import { mapGetters, mapActions } from "vuex";
import ReportIssuePage from "../pages/ReportIssuePage.vue";
import {
  Role,
  LOGIN_URL,
  getLocalOrgDetail,
  IS_DOH_THEME,
  Variant,
  VUE_APP_LAST_DEPLOYED,
  VUE_APP_RELEASE_VERSION,
  generateLocalFileUrl
} from "@/helpers/helper";
import WelcomeModel from "../components/reusable/WelcomeModel";

import { BASE_URL } from "@/service_urls";
export default {
  name: "TheHeader",
  components: {
    TheHeaderDropdownAccnt,
    ReportIssuePage,
    AlertModal,
    Notification,
    TheDynamicNavHeaders,
    Calendar,
    Modal,
    CandidateNotification,
    WelcomeModel,
  },
  data() {
    return {
      Role,
      Variant,
      page: "",
      pagePath: "",
      getOrgAcronym: "",
      isMobile: false,
      isSmall: false,
      IS_DOH_THEME,
      getLocalOrgDetail,
      isShowNotification: false,
      isShowCalendar: false,
      isShowAboutModal: false,
      VUE_APP_LAST_DEPLOYED,
      VUE_APP_RELEASE_VERSION,
      allocateModal: {
        modalColor: "primary",
        modalTitle: "New Job Allocated",
        modalContent: "You have new allocated jobs. See Job List.",
        isShowPopup: false,
        buttons: ["Ok"],
      },
      reportIssueModal: {
        isShowPopup: false,
        modalTitle: "Report a Problem",
      },
      generateLocalFileUrl
    };
  },
  computed: {
    ...mapGetters([
      "getRoleScopes",
      "getUser",
      "getUserId",
      "getImpersonationStatus",
      "getCandidateListUrlBasedOnRole",
      "getIsParentUser",
      "getImages",
      "currentUserType",
      "customerUserRoles",
      "getOrgName",
      "isCustomerUserRoleHave",
      "isDualUserRole",
      "isCustomerRecruiterUser",
      "getOrgDetail",
      "getOrgID",
      "isChildOfIndependentOrg",
      "getWindowWidth",
      "getUserAcl",
      "getUserDetails",
      "getNotificationAlerts",
      "getOrgVariant",
      "isCandidateSupplierFromAccessToken",
      "getNotification",
      "getShortNotify",
      "getShowAlert",
      "isIncustomeruserRole",
      "getCampaignById",
      "getCustomerNameFromAccessToken",
      "isShowWelcome",
      "getCandidateData",
    ]),
    pageRoute() {
      let text = "";
      if (this.page === "Candidate") {
        text = "Candidates > Talent Pool > Candidate Detailed View";
      } else if (this.page === "Job List Preview") {
        text = "Jobs > Job List > Job Detailed View";
      } else if (this.pagePath === "/add-contactuser") {
        text = "Settings > Users > Add User";
      } else if (this.pagePath === "/list-contactuser") {
        text = "Settings > Users > List User";
      } else if (this.pagePath === "/jobs-list/status/active") {
        text = "Jobs > View/Edit Job Board > Active Jobs";
      } else if (this.pagePath === "/jobs-list/status/inactive") {
        text = "Jobs > View/Edit Job Board > Inactive Jobs";
      } else if (this.pagePath === "/jobs-list/status/archive") {
        text = "Jobs > View/Edit Job Board > Archive Jobs";
      } else if (this.pagePath === "/list-job-templates") {
        text = "Settings > Job Templates";
      } else if (this.pagePath === "/add-organisation") {
        text = "Settings > Organisation > Add Organisation";
      } else if (this.pagePath === "/organisation-list") {
        text = "Settings > Organisation > List Organisation";
      }
      return text;
    },
    isCurrentUserTypeRecruiter() {
      return (this.currentUserType && this.currentUserType.code === 0) || false;
    },
    currentUserRole() {
      const curRole = this.getRoleScopes.length
        ? this.isCustomerRecruiterUser && !this.isCurrentUserTypeRecruiter
          ? "customer_user"
          : this.getRoleScopes[0]
        : "";
      const roleCheck = {
        customer_recruiter: "customer_recruiter",
        customer_admin: "customer_admin",
      };
      return this.getRoleScopes.length
        ? this.currentUserType?.code
          ? roleCheck[this.currentUserType?.code] || "customer_user"
          : this.getRoleScopes[0]
        : "";
    },
    isAdminLogin() {
      if (
        [
          this.Role.customerAdmin,
          this.Role.systemAdmin,
          this.Role.customerRecruiter,
          this.Role.systemRecruiter,
        ].includes(this.currentUserRole)
      )
        return true;
      return false;
    },
    isCampaignCandidate() {
      return this.getCandidateData?.is_campaign ? true : false;
    },
    isCandidate() {
      return (
        [this.Role.customerCandidate, this.Role.systemCandidate].includes(
          this.currentUserRole
        ) || false
      );
    },
    getUserName() {
      let username = this.getUser.first_name;
      let systemAdmin = 1;
      if (this.getUserId === systemAdmin) {
        username = this.getUser.user_name;
      }
      if (username) {
        return username.replace(/\w\S*/g, function (txt) {
          return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        });
      }
      return false;
    },
    getHeaderLogo() {
      if (this.page === "PreRegisterCampaign") {
        if (this.getCampaignById.uuid_logo_link_to_doc) {
          return `${BASE_URL}${this.getCampaignById.uuid_logo_link_to_doc?.replace(
            "/api/v1",
            ""
          )}?file=logo&${new Date().getTime()}`;
        }
      }
      return generateLocalFileUrl(this.getImages?.header_logo);
    },
    isDemoPage() {
      return [
        "Demo TalentFind",
        "Demo DOH",
        "Demo DOH JOB BOARD",
        "Demo DOH PQR CHECK",
      ].includes(this.page);
    },
    isIEFPage() {
      return this.page === "External IEF";
    },
    isCampaign() {
      return this.page === "RegisterCampaign";
    },
    isRegisterPage() {
      return [
        "Register",
        "Organisation Register",
        "Organisation with Location Register",
        "PreRegisterCampaign",
      ].includes(this.page);
    },
    notificationMessages() {
      return this.getNotificationAlerts.length
        ? this.getNotificationAlerts.length
        : 0;
    },
    isCustomerUser() {
      return (
        ["customer_recruiter", "customer_user"].includes(
          this.currentUserRole
        ) && this.currentUserType
      );
    },
    customerType() {
      return this.currentUserType?.label || "";
    },
    getTextColor() {
      return IS_DOH_THEME ? "text-white" : "text-primary";
    },
    getTextPrimaryColor() {
      return IS_DOH_THEME ? "text-white" : "text-primary-color";
    },
    isJobBoard() {
      let publicJobBoard = "Public Job Board";
      let publicOrgBasedJobBoard = "Public Org Based Job Board";
      return this.page == publicJobBoard || this.page == publicOrgBasedJobBoard
        ? true
        : false;
    },
    isAddPublicJob() {
      let page = "Add Public Jobs to Job Board";
      return this.page == page ? true : false;
    },
    isPublicJobDetail() {
      let page = "Public Job Board Detail";
      return this.page == page ? true : false;
    },
    getOrgWebsite() {
      return this.getOrgDetail?.website || "";
    },
    parentOrgQuery() {
      if (this.getIsParentUser) {
        return `?organisation=%5B${this.getOrgID}%5D`;
      }
      return "";
    },
    mobile() {
      return this.getWindowWidth <= 450 ? true : false;
    },
    desktop() {
      return this.getWindowWidth >= 1020 ? true : false;
    },
    isInterviewer() {
      return this.currentUserType?.code === 2 || false;
    },
  },
  created() {
    window.addEventListener("resize", () => {
      this.$store.commit("SET_WINDOW_WIDTH");
    });

    if (this.currentUserRole) {
      this.fetchUser();
      this.fetchAlert();
    }
  },
  updated() {
    if (
      window.matchMedia("only screen and (max-width: 359px)").matches &&
      this.$router.currentRoute.path !== "/"
    )
      this.isSmall = true;
    if (
      window.matchMedia("(min-width: 992px) and (max-width: 1250px)").matches &&
      this.$router.currentRoute.path !== "/"
    )
      this.isSmall = true;
    let isMobile =
      (window.matchMedia("only screen and (max-width: 540px)").matches &&
        this.$router.currentRoute.path !== "/") ||
      (window.matchMedia("only screen and (max-width: 1250px)").matches &&
        this.$router.currentRoute.path !== "/");
    this.isMobile = isMobile;
  },
  methods: {
    ...mapActions([
      "fetchUser",
      "resetCandidate",
      "fetchOrgDetailsByAcronym",
      "fetchSubmissionActionDetails",
      "fetchInterviewRequestAction",
      "getInterviewScheduledCandidateActionDetails",
      "fetchJobApprovalAction",
      "fetchJobsForDashboard",
      "getScratchPadItems",
      "fetchAlert",
      "clearAllNotificationAlerts",
    ]),
    calendarModalCallBack() {
      this.isShowCalendar = false;
    },
    userRegister() {
      this.resetCandidate();
      let { path_acronym, customer_uid, organisation_id, location_id } =
        getLocalOrgDetail();

      let path = `/${path_acronym}/register/${customer_uid}`;
      if (organisation_id)
        path += `/organisation/${organisation_id}/location/${location_id}`;

      this.$router.push({ path: path });
    },
    userSigin() {
      this.$router.push({ path: LOGIN_URL() });
    },
    toggleClass(ref) {
      if (this.$refs[ref]) {
        this.$refs[ref].hide(true);
      }
    },
    redirectToHome() {
      this.$router.push({
        path: `/${getLocalOrgDetail()?.path_acronym}`,
      });
    },
    alertModalCallBack() {
      this.$store.commit("SET_SHOW_ALERT", false);
    },
    isOpenNotification() {
      this.isShowNotification = !this.isShowNotification;
    },
    notifiModalCallBack() {
      this.isShowNotification = false;
    },
    aboutModel() {
      this.isShowAboutModal = true;
    },
    routFaq() {
      this.$router.push({
        path: `/faq`,
      });
    },
    allocateModalCallBack(action) {
      if (action === this.allocateModal.buttons[0]) {
        this.allocateModal.isShowPopup = false;
      }
    },
    reportIssueModalCallBack() {
      this.reportIssueModal.isShowPopup = false;
    },
    status(id) {
      return Notification.methods["notificationType"](
        Notification.data().groupedItem,
        id
      );
    },
    openReportIssueModal() {
      this.reportIssueModal.isShowPopup = true;
    },
    welcomeModeCallBack() {
      this.$store.commit("SET_WELCOME_MODEL", false);
    },
  },
  watch: {
    "$route.name": function () {
      this.page = this.$router.currentRoute.name;
      const {
        params: { org_name: pid },
      } = this.$router.currentRoute;
      this.getOrgAcronym = pid || "";
      this.fetchOrgDetailsByAcronym();
    },
    "$route.path": function () {
      this.pagePath = this.$router.currentRoute.path;
    },
    notificationMessages: function (value) {
      if (
        this.getNotificationAlerts?.some((e) => e?.alert_type_id == 10) &&
        this.isCandidateSupplierFromAccessToken
      ) {
        this.allocateModal.isShowPopup = true;
      } else {
        this.allocateModal.isShowPopup = false;
      }
    },
  },
};
</script>

<style lang="scss">
#loginUsernameMobile {
  display: none;
  margin-top: -10px;
}
.logo {
  width: 12.1875em;
}
.center {
  display: flex;
  justify-content: center;
}
.right {
  float: right;
}
.left {
  float: left;
}
#headerIconHolder,
.headerIconHolder {
  position: relative;
  float: right;
  /*height: -webkit-fill-available;*/
  margin-left: auto !important;
}
.fa-question-circle {
  color: #e2e2e2;
  margin-top: 2px;
  font-size: 36px;
}
.text {
  font-size: 1.25em;
  color: $text-base-color;
  font-weight: 400;
}

.menu-toggle {
  font-size: 24px;
}

.list-unstyled {
  margin-left: auto;
}

#loginUsernameMobile {
  width: 100%;
  text-align: right;
  font-size: 10px;
  margin-right: 5px;
  z-index: 999;
}

@media (max-width: 1024px) {
  .list-unstyled {
    margin-left: auto;
  }
  .additional-space {
    height: 30px;
  }
}
@media (max-width: 480px) {
  #loginUsername {
    display: none;
  }
  #loginUsernameMobile {
    display: block;
  }
  .avatar {
    width: 28px !important;
    height: auto !important;
  }
  .fa-question-circle {
    /*margin-top: 7px;*/
    font-size: 29px;
  }
  .calender {
    position: absolute;
    right: 40%;
    align-items: middle;
  }
}
@media (max-width: 450px) {
  #headerIconHolder {
    top: 0px;
    left: 0px;
  }
  .calender {
    left: -25px;
  }
  .title {
    font-size: 8px !important;
  }
}
@media only screen and (max-width: 452px) and (min-width: 436px) {
  #headerIconHolder {
    margin-top: 0px;
  }
}
// @media only screen and (max-width: 436px) and (min-width: 378px) {
//   #headerIconHolder {
//     top: 0px;
//   }
// }
@media only screen and (max-width: 350px) and (min-width: 320px) {
  .calender {
    left: -2px;
  }
}
@media only screen and (max-width: 479px) and (min-width: 453px) {
  .calender {
    left: -20px;
  }
}
@media (max-width: 1024px) {
  #loginUsername {
    display: none;
  }
  #loginUsernameMobile {
    display: block;
  }
  .avatar {
    width: 28px !important;
    height: auto !important;
  }
  .fa-question-circle {
    // margin-top: 7px;
    font-size: 28px;
  }
}
@media (max-width: 768px) and (min-width: 768px) {
  #loginUsername {
    display: block;
  }
  #loginUsernameMobile {
    display: none;
  }
}
.responsive {
  @media (max-width: 1250px) and (min-width: 1024px) {
    float: right;
    /*margin-left: 700px;*/
  }
  @media (max-width: 620px) and (min-width: 490px) {
    float: right;
    margin-left: 230px;
  }
  @media (max-width: 610px) {
    float: right;
  }
}

@media (max-width: 480px) {
  .flex-d {
    display: flex;
    align-items: center;
  }
  .alignItems {
    width: 53%;
  }
  .icon-align {
    justify-content: flex-end !important;
    width: 20%;
  }
  .align {
    width: 50%;
  }
  .alignWidth {
    margin-left: 10px !important;
    width: 60%;
  }
  .signup-link,
  .signup-link button {
    font-size: 14px !important;
    margin-left: auto;
  }
  .home-link,
  .home-link button {
    font-size: 12.4px !important;
    font-weight: 800px !important;
    margin-left: auto;
  }
}
.title {
  line-height: 11px;
  font-size: 10px;
}
.faq-icon {
  position: absolute;
  right: 2%;
}
.faq-clickable-area {
  height: 24px;
  width: 24px;
  padding: 2px 5px;
}
.text-primary-color {
  color: $text-primary-color;
}
@media (min-width: 481px) {
  .faq {
    margin-right: 5px;
  }
}
.fs-15 {
  font-size: 15px !important;
}
.bell-icon {
  color: #d4d4d4;
  font-size: 1.6em;
}
.calender-icon {
  font-size: 1.6em;
}
.badge-count {
  position: relative;
  top: -9px;
  left: -5px;
  background-color: #f35959;
  border: 2px solid #fff;
  border-radius: 15px !important;
  padding: 0.4em !important;
  justify-content: center;
}
.badge-circle {
  width: auto;
  height: 1.3rem;
  min-width: 1.6rem;
}
.bg-sidebar-color {
  background-color: $sidebar-color !important;
}
.faq,
.faq:hover,
.c-header-nav-link:hover {
  text-decoration: none;
}

.minify-notification {
  position: absolute;
  top: 55px;
  right: -115px;
  width: 300px;
  height: 30px;
  padding: 6px;
  color: white;
  background-color: #2eb85c;
  overflow: visible;
  z-index: 1050;
  box-shadow: 0 2px 4px 0 #0003, 0 2px 4px 0 #0003, inset 0 0 0 1px #00000003;
  border-radius: 5px;
  font-size: 11px !important;

  &::before {
    content: "";
    position: absolute;
    top: -20px;
    left: 140px;
    border-width: 10px;
    border-color: transparent #2eb85c transparent transparent;
    border-style: solid;
    transform: rotate(90deg);
  }
}
</style>
