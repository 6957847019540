<template>
  <div id="tfw-section-5a" class="section-5a">
    <div class="wrapper m-auto">
      <div class="wrap m-auto">
        <p class="paragraph-3 font-italic text-white">
          <img
            src="/img/tfw/doh.png"
            sizes="50vw"
            alt=""
            class="tablet-screen-shot"
          />
          “As a Regulatory Body, we interact with a number of Subject Matter
          Experts (SME) and Vendors in different fields and trust them not just
          for their service, but for the experience and knowledge they offer.
          OHR TalentFind and its pool of SMEs were super knowledgeable, flexible
          and patient during our journey of creating the First Intelligent End
          to End Health Workforce Attraction & Retention Platform – AD Health
          Kawader. We also appreciated their willingness to work with the First
          Dynamic Platform that highlights the Emirates of Abu Dhabi changing
          needs, critical gaps and Emiratization Priorities and that they have
          the capacity to handle so much more as we expand the project scope.
          They also provided some thoughts on our project outside of packaging,
          showing they were focussed not just with their piece of the puzzle,
          but interested in supporting our all-around growth. Their work on our
          Platform has made a significant difference to the Healthcare Sector.”
          <br>
          <span class="d-block">
            <b>Department of Health Abu Dhabi</b>
          </span>
        </p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "TFWSection5a",
};
</script>