<template>
  <div id="tfw-section-8" class="section-8">
    <div class="wrapper text-center" id="sec-8-wrapper">
      <div class="wrap mx-auto" id="sec-8-wrap">
        <h2 class="heading-6" id="sec-8-heading">Expand your global reach</h2>
        <div class="subhead w-100 text-centre text-white">
          GLOBAL OPPORTUNITIES DEDICATED TO HEALTHCARE CAREERS
        </div>
        <div class="button-wrap"></div>
      </div>
      <div class="row" id="sec-jobs_list">
        <div class="col-sm plain-div-block">
          <img
            src="/img/tfw/Cities_0004_big-ben-westminster-bridge-with-red-bus-london-uk.png"
            sizes="100vw"
            alt=""
            class="city-image"
          />
          <h1 class="centered" v-if="getCountryValues(182)">
            {{ getCountryValues(182) }}<br />
            {{ `JOB${getCountryValues(182) > 1 ? "S" : ""}` }}
          </h1>
          <CLink
            id="btn-182"
            class="btn btn-register w-button"
            :to="`/tfw/job-board/${customerID}/182`"
            :disabled="getCountryValues(182) < 1 && customerID != null"
            >UK</CLink
          >
        </div>
        <div class="col-sm plain-div-block">
          <img
            src="/img/tfw/Cities_0001_Dubai.png"
            sizes="100vw"
            alt=""
            class="city-image"
          />
          <h1 class="centered" v-if="getCountryValues(181)">
            {{ getCountryValues(181) }}<br />
            {{ `JOB${getCountryValues(181) > 1 ? "S" : ""}` }}
          </h1>
          <CLink
            id="btn-181"
            class="btn btn-register w-button"
            :to="`/tfw/job-board/${customerID}/181`"
            :disabled="getCountryValues(181) < 1 && customerID != null"
            >UAE</CLink
          >
        </div>
        <div class="col-sm plain-div-block">
          <img
            src="/img/tfw/Cities_0002_Kuwait.png"
            sizes="100vw"
            alt=""
            class="city-image"
          />
          <h1 class="centered" v-if="getCountryValues(92)">
            {{ getCountryValues(92) }}<br />
            {{ `JOB${getCountryValues(92) > 1 ? "S" : ""}` }}
          </h1>
          <CLink
            id="btn-92"
            class="btn btn-register w-button"
            :to="`/tfw/job-board/${customerID}/92`"
            :disabled="getCountryValues(92) < 1 && customerID != null"
            >KUWAIT</CLink
          >
        </div>
        <div class="col-sm plain-div-block">
          <img
            src="/img/tfw/saudi arabia.png"
            sizes="100vw"
            alt=""
            class="city-image"
          />
          <h1 class="centered" v-if="getCountryValues(149)">
            {{ getCountryValues(149) }} <br />{{
              `JOB${getCountryValues(149) > 1 ? "S" : ""}`
            }}
          </h1>
          <CLink
            id="btn-149"
            class="btn btn-register w-button"
            :to="`/tfw/job-board/${customerID}/149`"
            :disabled="getCountryValues(149) < 1 && customerID != null"
            >SAUDI ARABIA</CLink
          >
        </div>
        <div class="col-sm plain-div-block">
          <img
            src="/img/tfw/Cities_0003_USA.png"
            sizes="100vw"
            alt=""
            class="city-image"
          />
          <h1 class="centered">All Jobs</h1>
          <CLink
            class="btn btn-register w-button"
            :to="`/tfw/job-board/${customerID}`"
            >All</CLink
          >
        </div>
      </div>
      <div class="large-spacer"></div>
      <div class="wrap mx-auto">
        <h2 class="heading-6" id="sec-head-2">
          CONNECT WITH FUTURE POSSIBILITIES.
        </h2>
        <div class="button-wrap">
          <CLink
            class="btn btn-register w-button mx-1"
            to="/tfw/registration/candidate"
            >Candidates Register Now</CLink
          >
          <CLink
            class="btn button contact"
            href="https://www.talentfindsolutions.com/contact-us"
            target="_blank"
            >Employers Get in Touch</CLink
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import { getLocalOrgDetail } from "@/helpers/helper";
export default {
  name: "TFWSection8",
  computed: {
    ...mapGetters(["getDashboardCountryCount", "getOrgDetail"]),
    customerID() {
      return this.getOrgDetail?.customer_uid || null;
    },
  },
  methods: {
    ...mapActions(["fetchDashboardCountryCount"]),
    getCountryValues(country_id) {
      let count =
        this.getDashboardCountryCount?.filter(
          (ele) => ele.country_id == country_id
        ) || [];
      return count?.length ? count[0].job_count : 0;
    },
    triggerCountryCount() {
      const customer_uid = getLocalOrgDetail()?.customer_uid;
      if (customer_uid) {
        return this.fetchDashboardCountryCount(customer_uid);
      }
    },
  },
  mounted() {
    this.triggerCountryCount();
  },
  watch: {
    getOrgDetail(newVal, oldVal) {
      if (newVal) {
        this.triggerCountryCount();
      }
    },
  },
};
</script>
