<template>
  <div class="session-wrapper" v-if="isCustomerLogin">
    <div class="session" v-if="isShowcountDown">
      <div
        class="modal overflow-auto fade show d-block _0Gn3"
        tabindex="-1"
        role="dialog"
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content border-0 _2HQv3">
            <div class="modal-body">
              Your session
              <span v-if="isAccessTokenExpired">
                will expire in
                <span>{{ displayMin }}</span> :
                <span>{{ displaySec }}</span>
                minutes.
              </span>
              <span v-else> has expired. </span>
              <span v-if="isRefeshTokenExpired">
                Do you want to extend the session?</span
              >
            </div>
            <div
              class="pt-4 d-flex justify-content-end"
              v-if="!isRefeshTokenExpired"
            >
              <button
                type="button"
                class="btn btn-primary mx-1"
                @click="logout(), showHideTimeOut()"
              >
                Log Out
              </button>
            </div>
            <div class="pt-4 d-flex justify-content-end" v-else>
              <button
                type="button"
                class="btn btn-outline-primary mx-1"
                @click="showHideTimeOut()"
              >
                No
              </button>
              <button
                type="button"
                class="btn btn-primary mx-1"
                data-dismiss="modal"
                @click="refreshToken(), showHideTimeOut()"
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { parseJwt, Role, getScope } from "@/helpers/helper";
export default {
  components: {},
  data() {
    return {
      Role,
      displayMin: "00",
      displaySec: "00",
      isShowcountDown: false,
      isAccessTokenExpired: true,
      isRefeshTokenExpired: true,
    };
  },
  watch: {},
  computed: {
    ...mapGetters(["getWindowWidth", "getRoleScopes", "getSessionDetails"]),
    currentUserRole() {
      const roleCheck = {
        customer_recruiter: "customer_recruiter",
        customer_admin: "customer_admin",
      };
      return this.getRoleScopes.length
        ? this.currentUserType?.code
          ? roleCheck[this.currentUserType?.code] || "customer_user"
          : this.getRoleScopes[0]
        : "";
    },
    isCustomerLogin() {
      if (
        [this.Role.customerAdmin, this.Role.customerRecruiter].includes(
          this.currentUserRole
        )
      )
        return true;
      return false;
    },
    isSystemLogin() {
      if (
        [this.Role.systemAdmin, this.Role.systemRecruiter].includes(
          this.currentUserRole
        )
      )
        return true;
      return false;
    },
    seconds() {
      return 1000;
    },
    minutes() {
      return this.seconds * 60;
    },
    hours() {
      return this.minutes * 60;
    },
  },
  methods: {
    ...mapActions(["refreshToken", "logout"]),
    accessTokenTimer() {
      const timer = setInterval(() => {
        const now = new Date(new Date().toUTCString()).getTime();
        const end = this.getSessionDetails?.access?.exp * this.seconds;
        const distance = end - now;

        if (distance < 0) {
          this.isAccessTokenExpired = false;
          clearInterval(timer);
          return;
        }
        if (distance == 300 * this.seconds) {
          this.isShowcountDown = true;
        }

        const minutes = Math.floor((distance % this.hours) / this.minutes);
        const seconds = Math.floor((distance % this.minutes) / this.seconds);
        this.displayMin = this.formateTime(minutes);
        this.displaySec = this.formateTime(seconds);
      }, this.seconds);
    },
    refreshTokenTimer() {
      const _timer = setInterval(() => {
        const now = new Date(new Date().toUTCString()).getTime();
        const end = this.getSessionDetails?.refresh?.exp * this.seconds;
        const distance = end - now;

        if (distance < 0) {
          this.isRefeshTokenExpired = false;
          clearInterval(_timer);
          return;
        }
      }, this.seconds);
    },
    formateTime(val) {
      return val < 10 ? "0" + val : val;
    },
    showHideTimeOut() {
      this.isShowcountDown = !this.isShowcountDown;
    },
  },
  created() {
    this.accessTokenTimer();
    this.refreshTokenTimer();
  },
};
</script>
<style lang="scss" scoped>
._0Gn3 {
  background-color: #f0f2f5;
  z-index: 99999 !important;
}
._2HQv3 {
  padding: 22px 24px 20px;
  background-color: var(--modal-background);
  border-radius: 3px;
  box-shadow: 0 17px 50px 0 rgba(var(--shadow-rgb), 0.19),
    0 12px 15px 0 rgba(var(--shadow-rgb), 0.24);
}
</style>
