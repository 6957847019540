<template>
  <div id="tfw-section-3" class="section-3">
    <div class="wrapper">
      <CRow class="text-center">
        <CCol md="12">
          <div class="wrap text-center">
            <h2 class="heading-4">
              One Profile. <br />A World of Healthcare Opportunities.
            </h2>
            <div class="subhead">
              CREATE YOUR GLOBAL CAREERS PROFILE. LET THE JOBS COME TO YOU
            </div>
          </div>
        </CCol>
        <CCol md="12">
          <img
            src="/img/tfw/1-p-1080.png"
            width="341"
            sizes="(max-width: 479px) 100vw, 65vw"
            srcset="
              /img/tfw/1-p-500.png   500w,
              /img/tfw/1-p-800.png   800w,
              /img/tfw/1-p-1080.png 1080w,
              /img/tfw/1.png        1500w
            "
            alt=""
            class="image-2"
          />
        </CCol>
        <CCol md="12">
          <CLink
            class="btn btn-register w-button"
            to="/tfw/registration/candidate"
            >Create your Free Digital CV and Careers Passport.</CLink
          >
        </CCol>
      </CRow>
    </div>
  </div>
</template>
<script>
export default {
  name: "TFWSection3",
};
</script>