<template>
  <div id="tfw-section-4" class="section-4 feature-wrap">
    <div class="feature-wrap reverse full-width">
      <div class="feature-content">
        <h2 class="text-center text-white heading-4">Connecting your career</h2>
        <div class="text-center w-100 text-white subhead">
          What you can expect to find on talentfind world
        </div>
        <div class="spacer"></div>
        <div class="row">
          <div class="col-md-4 plain-div-block">
            <img
              src="/img/tfw/Artboard-3.png"
              sizes="(max-width: 991px) 60px, 80px"
              alt=""
              class="purple-div-icon"
            />
            <p class="white-paragraph center">Regional &<br />International Jobs</p>
          </div>
          <div class="col-md-4 plain-div-block">
            <img
              src="/img/tfw/Artboard-8.png"
              sizes="(max-width: 991px) 60px, 80px"
              alt=""
              class="purple-div-icon"
            />
            <p class="white-paragraph center">Training & Skills<br />Upgrade</p>
          </div>
          <div class="col-md-4 plain-div-block">
            <img
              src="/img/tfw/Artboard-10.png"
              sizes="(max-width: 991px) 60px, 80px"
              alt=""
              class="purple-div-icon"
            />
            <p class="white-paragraph center">Licensure<br />Eligibility Check</p>
          </div>
          <div class="col-md-4 plain-div-block">
            <img
              src="/img/tfw/Artboard-7.png"
              sizes="(max-width: 991px) 60px, 80px"
              alt=""
              class="purple-div-icon"
            />
            <p class="white-paragraph center">Create Your Global<br />Careers Passport</p>
          </div>
          <div class="col-md-4 plain-div-block">
            <img
              src="/img/tfw/Artboard-9.png"
              sizes="(max-width: 991px) 60px, 80px"
              alt=""
              class="purple-div-icon"
            />
            <p class="white-paragraph center">
              Credentialling &<br />
              Compliance Services
            </p>
          </div>
          <div class="col-md-4 plain-div-block">
            <img
              src="/img/tfw/coming_soon.png"
              sizes="(max-width: 991px) 60px, 80px"
              alt=""
              class="purple-div-icon"
            />
            <p class="white-paragraph center">And More To Come!</p>
          </div>
        </div>
        <div>
          <p class="white-paragraph text-left">
          Create your digital CV and showcase your skills, experience and education.  
          Keep your profile private or make it public for recruiters to see worldwide. 
          Easy access to CME or English language training, apply to regional and international opportunities, 
          store your professional documents in our Document Vault, 
          and utilise our services designed to help you thrive throughout your healthcare career.
          </p>
          <div class="text-center">
            <CLink
              class="btn btn-register"
              to="/tfw/registration/candidate"
              >register now</CLink
            >
          </div>
        </div>
      </div>
      <div class="feature-image">
        <img
          src="/img/tfw/Nurse.png"
          width="386"
          sizes="(max-width: 479px) 98vw, 30vw"
          alt=""
          class="image-2"
        />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "TFWSection4",
};
</script>