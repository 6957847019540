<template>
  <div class="pr-0">
    <CDropdown
      inNav
      class="c-header-nav-items list-style-none"
      placement="bottom-end"
      add-menu-classes="pt-0"
      ref="profile"
      @mouseover.native="show()"
      @mouseleave.native="hide()"
      @click.native="checkVisible ? hide() : show()"
    >
      <template #toggler>
        <CHeaderNavLink id="user-settings-dropdown">
          <div class="c-avatar text d-flex align-items-baseline">
            <img
              class="avatar rounded-circle"
              alt="profile"
              :src="profilePic"
              @error="$event.target.src = '/img/user_default_pic.png'"
            />
          </div>
        </CHeaderNavLink>
      </template>
      <CDropdownHeader tag="div" class="text-center" color="light">
        <strong>Settings</strong>
      </CDropdownHeader>
      <a
        name="switch-role-link"
        class="dropdown-item"
        @click="switchRole"
        v-if="
          currentUserRole === Role.systemAdmin ||
            getImpersonationStatus.impersonation
        "
      >
        <em class="fas fa-user mr-2"></em>Switch Role
      </a>
      <a
        name="switch-user-type"
        class="dropdown-item"
        @click="switchUserType"
        v-if="isMultiRole"
      >
        <CIcon name="cil-user" />Switch User Type
      </a>
      <a
        class="dropdown-item"
        name="change-password-link"
        @click="changePassword">
        <em class="fas fa-key mr-2" style="margin-top: 1px"></em>Change
        Password
      </a>
      <a
        name="update-picture-link"
        class="dropdown-item"
        v-if="isNotCandidates && !isCandidateSupplierFromAccessToken"
        @click="updateOrgPictures()"
      >
        <em class="fas fa-user-circle mr-2" style="margin-top: 1px"></em>Update
        Picture
      </a>
      <a
        name="notification-config-link"
        class="dropdown-item"
        v-if="isNotCandidates && !isCandidateSupplierFromAccessToken"
        @click="notificationConfig()"
      >
        <em class="fas fa-bell mr-2" style="margin-top: 1px"></em>Notification
      </a>
      <a name="log-out-link" class="dropdown-item" @click="clearLocalStorage">
        <em class="fas fa-sign-out-alt mr-2" style="margin-top: 1px"></em>Logout
      </a>
    </CDropdown>
    <SwitchAccountModal
      :isShowPopup="isShowPopup"
      :modalCallBack="modalCallBack"
      :impersonation="getImpersonationStatus.impersonation"
    />
    <SwitchUserTypeModal
      v-if="customerUserModal.isShowPopup"
      :isShowPopup="customerUserModal.isShowPopup"
      :currentUserType="currentUserType"
      @modalCallBack="SwitchUserTypeModalCallBack" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import SwitchAccountModal from "./SwitchAccountModal";
import SwitchUserTypeModal from "./SwitchUserTypeModal";
import { BASE_URL } from "@/service_urls";
import { getScope, Role, appendAccessToken } from "@/helpers/helper";
import Select from "@/components/reusable/Fields/Select.vue";

export default {
  name: "TheHeaderDropdownAccnt",
  components: {
    SwitchAccountModal,
    SwitchUserTypeModal,
    Select,
  },
  data() {
    return {
      itemsCount: 42,
      isShowPopup: false,
      Role,
      customerUserModal: {
        isShowPopup: false,
      },
      isShowCandidatePopup: false,
      switchCandidate: {},
    };
  },
  computed: {
    ...mapGetters([
      "getImpersonationStatus",
      "getRoleScopes",
      "getUploadedDocument",
      "getUserUploadedDocuments",
      "currentUserType",
      "isCustomerUserRoleHave",
      "getRole",
      "getUser",
      "uploaded",
      "customerUserRoles",
      "isCandidateSupplierFromAccessToken",
    ]),
    currentUserRole() {
      return this.getRoleScopes.length ? this.getRoleScopes[0] : "";
    },
    isNotCandidates() {
      return (
        [this.Role.customerAdmin, this.Role.customerRecruiter].includes(
          this.currentUserRole
        ) || false
      );
    },
    profilePic() {
      if (
        [this.Role.customerCandidate, this.Role.systemCandidate].includes(
          this.currentUserRole
        )
      ) {
        let profileData = this.getUploadedDocument
          .filter(({ document_type_id }) => document_type_id === 2)
          .reduce((acc, cur) => {
            return cur;
          }, {});
        if (Object.keys(profileData).length) {
          let _baseURL = BASE_URL;
          let _base_domain = _baseURL.substring(0, _baseURL.indexOf("/api"));
          return appendAccessToken(`${_base_domain}${profileData.uuid_link_to_doc}`);
        }
        return "/img/user_default_pic.png";
      } else if (this.isNotCandidates && this.getUser) {
        let profileData = this.getUserUploadedDocuments
          .filter((document) => document.document_type_id === 2)
          .reduce((acc, cur) => {
            return cur;
          }, {});
        if (
          Object.keys(profileData).length &&
          this.getUser?.id == profileData.user_id
        ) {
          let _baseURL = BASE_URL;
          let _base_domain = _baseURL.substring(0, _baseURL.indexOf("/api"));
          return `${_base_domain}/api/v1/user_documents/${profileData.user_document_id}/file?user_id=${profileData.user_id}`;
        }
        return "/img/user_default_pic.png";
      } else {
        return "/img/user_default_pic.png";
      }
    },
    isMultiRole() {
      return this.customerUserRoles.length > 1 || this.getRoleScopes.length > 1;
    },
    checkVisible() {
      return this.$refs?.profile?._data?.visible;
    },
    options() {
      return {
        CandidateOrganisations: this.candidateOrganisations,
      };
    },
    candidateOrganisations(){
        return this.getCandidateOrganisation.map((item, index) => ({
        id: index,
        label: item.organisation?.name || item.customer?.name,
      }));
    },
  },
  methods: {
    ...mapActions([
      "logout",
      "fetchFacilityList",
      "startImpersonation",
      "stopImpersonation",
    ]),
    clearLocalStorage() {
      this.logout();
    },
    switchRole() {
      if (!this.getImpersonationStatus.impersonation) {
        this.fetchFacilityList();
      }
      this.isShowPopup = true;
    },
    modalCallBack(action, value) {
      const _action = {
        customerAdmin: "CustomerAdmin",
        systemAdmin: "SystemAdmin",
        cancel: "Cancel",
      };
      if (action === _action.customerAdmin) {
        let payload = {
          customer_id: value.customer_uid,
          role_id: 4,
        };
        this.startImpersonation(payload);
      } else if (action === _action.systemAdmin) {
        this.stopImpersonation();
      }
      this.isShowPopup = false;
    },
    changePassword() {
      this.$store.commit("SET_PREVIOUS_PAGE_URL", this.$route.fullPath);
      this.$router.push({ path: "/change-password" });
    },
    updateOrgPictures() {
      this.$store.commit("SET_PREVIOUS_PAGE_URL", this.$route.fullPath);
      this.$router.push({ path: "/upload-org-pictures" });
    },
    notificationConfig() {
      this.$store.commit("SET_PREVIOUS_PAGE_URL", this.$route.fullPath);
      this.$router.push({ path: "/notification-config" });
    },
    switchUserType() {
      this.customerUserModal.isShowPopup = true;
    },
    SwitchUserTypeModalCallBack(payload) {
      if (payload) {
        this.$store.commit("SET_CURRENT_USER_TYPE", payload);
        this.$store.commit("SET_ACTIVE_TAB", 0);
        this.$store.commit("SET_ACTIVE_SUB_TAB", 0);
        if (
          ["customer_recruiter", "customer_admin"].includes(
            this.currentUserType?.code
          )
        )
          this.$router.push("/recruiter-dashboard");
        else if(this.currentUserType?.code === 6)
           this.$router.push("/management-report");
        else this.$router.push("/list-activity");
      }
      this.customerUserModal.isShowPopup = false;
    },
    show() {
      if (this.$refs.profile) this.$refs.profile._data.visible = true;
    },
    hide() {
      if (this.$refs.profile) this.$refs.profile._data.visible = false;
    },
    handleChangeSelectCandidate(name, value) {
      this.$set(this.switchCandidate, name, value);
      this.switchOrganisationId = value.id
    },


  },
};
</script>

<style lang="scss" scoped>
.c-icon {
  margin-right: 0.3rem;
}
.avatar {
  width: 36px;
  height: 36px;
}
.dropdown-item {
  cursor: pointer;
}
::marker {
  color: $sidebar-color;
}
</style>
